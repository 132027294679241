import { Product } from "@ludens-reklame/rubics-v3-sdk/product/types";
import { RichText } from "../../internal-components/richText/RichText.js";
import { arrow } from "../../icons/svg.js";

interface Props {
  text?: string;
  membership?: Product;
  rows?: {
    product?: string;
    price?: string;
  }[];
}

const Memberships: React.FC<Props> = ({ text, membership, rows = [] }) => {
  return (
    <div className="membership-wrapper mw hs vs-xl">
      <h2 className="title">Hva koster medlemskap?</h2>
      <div className="container">
        <ul className="mobile">
          {rows.map((row) => (
            <li key={row.product}>
              <h6>{row.product}</h6>
              <h6>{row.price}</h6>
            </li>
          ))}
        </ul>
        <table className="desktop">
          <thead>
            <tr>
              <th className="h4">Medlemskap</th>
              <th className="h4">Pris</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.product}>
                <td className="h6">{row.product}</td>
                <td className="h6">{row.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {text && <RichText html={text} />}
        {membership && (
          <div className="btn-container">
            <a className="button" href={membership.url}>
              Bli medlem {arrow}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Memberships;
